import styled from 'styled-components'

export const Container = styled.div`
  /* position: relative;
  display: block; */
  width: 100%;
  height: 50vh;
  padding: 2vh;
  background-color: #311210;
  & .imgwrpfx {
    width: 100%;
    height: 46vh;
  }
`